<app-pagetitle title="Courses" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>

<div>
    <div class="card">
        <div class="card-body">
            <form [formGroup]="searchForm" class="col-lg-12 d-flex gap-1">
                <div class="row">
                    <div class="col-xl col-md-6">
                        <label for="example-datetime-local-input" class="form-label">Start Date</label>
                        <input class="form-control" type="date" placeholder="Start" formControlName="startDate"
                            id="example-datetime-local-input">
                    </div>
                    <div class="col-xl-2 col-md-6">
                        <label for="example-datetime-local-input" class="form-label">End Date</label>
                        <input class="form-control" type="date" placeholder="End" formControlName="endDate"
                            id="example-datetime-local-input">
                    </div>
                    <div class="col-xl col-md-6">
                        <label for="county">County</label>
                        <ng-select id="county"  [items]="counties" [multiple]="true" (change)="subCounties($event)"  [virtualScroll]="true" bindLabel="name" bindValue="county_id"
                        placeholder="County" formControlName="countyId">
                        </ng-select> 
                    </div>
                    <div class="col-xl col-md-6">
                        <label for="county">Sub County</label>
                        <ng-select id="county"  [items]="sub_counties" [multiple]="true" (change)="getWards($event)"  [virtualScroll]="true" bindLabel="name" bindValue="subCountyId"
                        placeholder="Sub County" formControlName="subCountyId">
                        </ng-select> 
                    </div>
                    <div class="col-xl col-md-6">
                        <label for="county">Ward</label>
                        <ng-select id="county"  (change)="filterGroups($event)" [items]="wards" [multiple]="true"  [virtualScroll]="true" bindLabel="name" bindValue="wardId"
                        placeholder="Ward" formControlName="wardId">
                        </ng-select> 
                    </div>  
                    <div class="col-xl col-md-6">
                        <label for="county">Group</label>
                        <ng-select id="county"  [items]="groups" [multiple]="true"  [virtualScroll]="true" bindLabel="group_name" bindValue="group_id"
                        placeholder="Group" formControlName="groupId">
                        </ng-select> 
                    </div>   
                    <div class="col-xl col-md-6 d-flex align-items-end">
                        <button (click)="search()" style="color: #e9ecef;margin-bottom: 2px;" class="btn w-100 secondary-color btn-block mt-medium" type="submit">search</button>
                    </div>  
                </div>
            </form>
        </div>
    </div>
    <div class="card">
        <!-- <div class="card-body">
            <mat-form-field>
                <mat-label>Filter</mat-label>
                <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Mia" #input>
              </mat-form-field>
              
              <div class="mat-elevation-z8">
                <table mat-table [dataSource]="dataSource" matSort>
              
                  <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                    <td mat-cell *matCellDef="let row"> {{row.name}} </td>
                  </ng-container>
              
                  <ng-container matColumnDef="course">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Course </th>
                    <td mat-cell *matCellDef="let row"> {{row.course.title }} </td>
                  </ng-container>
              
                  <ng-container matColumnDef="course_module">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Module </th>
                    <td mat-cell *matCellDef="let row"> {{ row.course_module.title }} </td>
                  </ng-container>
              
                  <ng-container matColumnDef="training_date">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Training_date </th>
                    <td mat-cell *matCellDef="let row"> {{ row.training_date }} </td>
                  </ng-container>

                  <ng-container matColumnDef="next_training_date">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Training_date </th>
                    <td mat-cell *matCellDef="let row"> {{ row.next_training_date }} </td>
                  </ng-container>

                  <ng-container matColumnDef="members_trained">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Members Trained </th>
                    <td mat-cell *matCellDef="let row"> {{ row.total_members_trained }} </td>
                  </ng-container>

                  <ng-container matColumnDef="value_chain_name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> VLC </th>
                    <td mat-cell *matCellDef="let row"> {{ row.value_chain_name }} </td>
                  </ng-container>
                                
                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              
                  <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
                  </tr>
                </table>
              
                <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of users"></mat-paginator>
              </div>             
        </div> -->   

        <ngx-datatable 
        class="bootstrap no-detail-row"
        [rows]="trainings" 
        [rowHeight]="50" 
        (page)="setPage($event)"
        class="bootstrap core-bootstrap" 
        [columnMode]="'force'" 
        [headerHeight]="50" 
        [footerHeight]="50"
        [count]="200"
        [limit]="dataParams.page_size" >
          <ngx-datatable-column name="S/No" prop="sn">
          <ng-template let-rowIndex="rowIndex" ngx-datatable-cell-template>
          <span>{{rowIndex+1}}</span>
          </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Date"  prop="created_at" >
              <ng-template let-value="value" ngx-datatable-cell-template>
                  {{ value | date }}
              </ng-template>
          </ngx-datatable-column>                                      
          <ngx-datatable-column name="Course"  prop="course.title" ></ngx-datatable-column>
          <ngx-datatable-column name="Module"  prop="course_module.title" ></ngx-datatable-column>
          <ngx-datatable-column name="Trainer"  prop="trainer.name" ></ngx-datatable-column>
          <ngx-datatable-column name="VLC"  prop="value_chain.value_chain_name"></ngx-datatable-column>
          <ngx-datatable-column name="Next Training Date"  prop="next_training_date" >
              <ng-template let-value="value" ngx-datatable-cell-template>
                  {{ value | date }}
              </ng-template>
          </ngx-datatable-column> 
          <ngx-datatable-column name="Details"  prop="details" >
              <ng-template let-row="row" ngx-datatable-cell-template>
              <button  (click)="viewDetails(row)" class="round" class="btn btn-sm btn-warning">Details</button>
              </ng-template>
          </ngx-datatable-column>
      </ngx-datatable>
    </div>
</div>
<!-- start page title -->
<app-pagetitle
  title="Dashboard"
  [breadcrumbItems]="breadCrumbItems"
></app-pagetitle>
<!-- end page title -->

<div>
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div>
          <form
            [formGroup]="searchForm"
            class="col-lg-12 d-flex w-100"
            style="padding-right: 0px !important"
          >
            <div
              class="d-flex flex-sm-column flex-md-column flex-lg-row flex-wrap justify-content-between w-100"
            >
              <div class="d-flex flex-column">
                <label for="example-datetime-local-input" class="form-label"
                  >Start Date</label
                >
                <input
                  style="height: 43px !important"
                  class="form-control"
                  type="date"
                  placeholder="Start"
                  formControlName="startDate"
                  id="example-datetime-local-input"
                />
              </div>
              <div class="d-flex flex-column">
                <label for="example-datetime-local-input" class="form-label"
                  >End Date</label
                >
                <input
                  style="height: 43px !important"
                  class="form-control"
                  type="date"
                  placeholder="End"
                  formControlName="endDate"
                  id="example-datetime-local-input"
                />
              </div>
              <div class="d-flex flex-column">
                <label for="county">County</label>
                <p-multiSelect
                  class="fixed-width-multiselect"
                  formControlName="countyId"
                  [options]="counties"
                  [(ngModel)]="selectedCounty"
                  optionLabel="name"
                  placeholder="Select county"
                  (onChange)="filter($event.value)"
                ></p-multiSelect>
              </div>
              <div class="d-flex flex-column">
                <label for="county">Sub County</label>
                <p-multiSelect
                  [options]="subcountyOptions"
                  formControlName="subCountyId"
                  [(ngModel)]="selectedSubcounty"
                  optionLabel="name"
                  placeholder="Select a subcounty"
                  (onChange)="filterWards($event.value)"
                ></p-multiSelect>
              </div>
              <div class="d-flex flex-column">
                <label for="county">Ward</label>
                <p-multiSelect
                  [options]="wardOptions"
                  formControlName="wardId"
                  [(ngModel)]="selectedWard"
                  optionLabel="name"
                  placeholder="Select a ward"
                ></p-multiSelect>
              </div>
              <div class="d-flex flex-column">
                <label for="county">Group</label>
                <p-multiSelect
                  [options]="myGroups"
                  formControlName="groupId"
                  [(ngModel)]="selectedGroup"
                  optionLabel="name"
                  placeholder="Select a group"
                ></p-multiSelect>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div style="width: 20%">
    <div style="background-color: #fff" class="card shadow card-h-100">
      <div class="card-body d-flex align-items-center justify-content-center">
        <div class="row align-items-center">
          <div class="col-6 d-flex flex-column align-items-center gap-3 w-100">
            <span class="lh-1 d-block text-truncate" style="color: #3fc057"
              >Number of Tots</span
            >
            <div
              class="d-flex flex-row align-items-center justify-content-center gap-3"
            >
              <span
                [countUp]="ToTsNo"
                class="counter-value"
                style="color: #3fc057; font-size: 30px; font-weight: 700"
              ></span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="0 0 640 512"
              >
                <path
                  fill="#3FC057"
                  d="M208 352c-2.39 0-4.78.35-7.06 1.09C187.98 357.3 174.35 360 160 360c-14.35 0-27.98-2.7-40.95-6.91c-2.28-.74-4.66-1.09-7.05-1.09C49.94 352-.33 402.48 0 464.62C.14 490.88 21.73 512 48 512h224c26.27 0 47.86-21.12 48-47.38c.33-62.14-49.94-112.62-112-112.62m-48-32c53.02 0 96-42.98 96-96s-42.98-96-96-96s-96 42.98-96 96s42.98 96 96 96M592 0H208c-26.47 0-48 22.25-48 49.59V96c23.42 0 45.1 6.78 64 17.8V64h352v288h-64v-64H384v64h-76.24c19.1 16.69 33.12 38.73 39.69 64H592c26.47 0 48-22.25 48-49.59V49.59C640 22.25 618.47 0 592 0"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div style="width: 20%">
    <div class="card card-h-100" style="background-color: #f77e18">
      <div class="card-body d-flex align-items-center justify-content-center">
        <div class="row align-items-center">
          <div class="col-6 d-flex flex-column align-items-center gap-3 w-100">
            <span class="lh-1 d-block text-truncate" style="color: white"
              >Total Farmers</span
            >
            <div class="d-flex flex-row align-items-center gap-3">
              <span
                [countUp]="totalMembers"
                class="counter-value"
                style="color: white; font-size: 30px; font-weight: 700"
              ></span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="0 0 16 16"
              >
                <path
                  fill="#fff"
                  d="M15 14s1 0 1-1s-1-4-5-4s-5 3-5 4s1 1 1 1zm-7.978-1L7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276c.593.69.758 1.457.76 1.72l-.008.002l-.014.002zM11 7a2 2 0 1 0 0-4a2 2 0 0 0 0 4m3-2a3 3 0 1 1-6 0a3 3 0 0 1 6 0M6.936 9.28a6 6 0 0 0-1.23-.247A7 7 0 0 0 5 9c-4 0-5 3-5 4q0 1 1 1h4.216A2.24 2.24 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904c.243-.294.526-.569.846-.816M4.92 10A5.5 5.5 0 0 0 4 13H1c0-.26.164-1.03.76-1.724c.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0a3 3 0 0 1-6 0m3-2a2 2 0 1 0 0 4a2 2 0 0 0 0-4"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div style="width: 20%">
    <div class="card card-h-100" style="background-color: #f65252">
      <div class="card-body d-flex align-items-center justify-content-center">
        <div class="row align-items-center">
          <div class="col-6 d-flex flex-column align-items-center gap-3 w-100">
            <span class="lh-1 d-block text-truncate" style="color: white"
              >Total Groups</span
            >
            <div class="d-flex flex-row align-items-center gap-3">
              <span
                [countUp]="totalGroups"
                class="counter-value"
                style="color: white; font-size: 30px; font-weight: 700"
              ></span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="0 0 24 24"
              >
                <path
                  fill="none"
                  stroke="#fff"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M17 20c0-1.657-2.239-3-5-3s-5 1.343-5 3m14-3c0-1.23-1.234-2.287-3-2.75M3 17c0-1.23 1.234-2.287 3-2.75m12-4.014a3 3 0 1 0-4-4.472m-8 4.472a3 3 0 0 1 4-4.472M12 14a3 3 0 1 1 0-6a3 3 0 0 1 0 6"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div style="width: 20%">
    <div class="card card-h-100" style="background-color: #3fc057">
      <div class="card-body d-flex align-items-center justify-content-center">
        <div class="row align-items-center">
          <div class="col-6 d-flex flex-column align-items-center gap-3 w-100">
            <span class="lh-1 d-block text-truncate" style="color: white"
              >Total Income</span
            >
            <div class="d-flex flex-row align-items-center gap-3">
              <span
                [countUp]="totalIncome"
                class="counter-value"
                style="color: white; font-size: 30px; font-weight: 700"
              ></span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 512 512"
              >
                <path
                  fill="#fff"
                  d="M416 208c0 45.9-14.9 88.3-40 122.7l126.6 126.7c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0s208 93.1 208 208M228 104c0-11-9-20-20-20s-20 9-20 20v14c-7.6 1.7-15.2 4.4-22.2 8.5c-13.9 8.3-25.9 22.8-25.8 43.9c.1 20.3 12 33.1 24.7 40.7c11 6.6 24.7 10.8 35.6 14l1.7.5c12.6 3.8 21.8 6.8 28 10.7c5.1 3.2 5.8 5.4 5.9 8.2c.1 5-1.8 8-5.9 10.5c-5 3.1-12.9 5-21.4 4.7c-11.1-.4-21.5-3.9-35.1-8.5c-2.3-.8-4.7-1.6-7.2-2.4c-10.5-3.5-21.8 2.2-25.3 12.6s2.2 21.8 12.6 25.3c1.9.6 4 1.3 6.1 2.1c8.3 2.9 17.9 6.2 28.2 8.4V312c0 11 9 20 20 20s20-9 20-20v-13.8c8-1.7 16-4.5 23.2-9c14.3-8.9 25.1-24.1 24.8-45c-.3-20.3-11.7-33.4-24.6-41.6c-11.5-7.2-25.9-11.6-37.1-15l-.7-.2c-12.8-3.9-21.9-6.7-28.3-10.5c-5.2-3.1-5.3-4.9-5.3-6.7c0-3.7 1.4-6.5 6.2-9.3c5.4-3.2 13.6-5.1 21.5-5c9.6.1 20.2 2.2 31.2 5.2c10.7 2.8 21.6-3.5 24.5-14.2s-3.5-21.6-14.2-24.5c-6.5-1.7-13.7-3.4-21.1-4.7z"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div style="width: 20%">
    <div class="card card-h-100" style="background-color: #238468">
      <div class="card-body d-flex align-items-center justify-content-center">
        <div class="row align-items-center">
          <div class="col-6 d-flex flex-column align-items-center gap-3 w-100">
            <span class="lh-1 d-block text-truncate" style="color: white"
              >Survey members statistics</span
            >
            <div class="d-flex flex-row align-items-center gap-3">
              <span
                [countUp]="totalSurveyMembers"
                class="counter-value"
                style="color: white; font-size: 30px; font-weight: 700"
              ></span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="0 0 48 48"
              >
                <path
                  fill="#455a64"
                  d="M36 4H26c0 1.1-.9 2-2 2s-2-.9-2-2H12C9.8 4 8 5.8 8 8v32c0 2.2 1.8 4 4 4h24c2.2 0 4-1.8 4-4V8c0-2.2-1.8-4-4-4"
                />
                <path
                  fill="#fff"
                  d="M36 41H12c-.6 0-1-.4-1-1V8c0-.6.4-1 1-1h24c.6 0 1 .4 1 1v32c0 .6-.4 1-1 1"
                />
                <g fill="#90a4ae">
                  <path
                    d="M26 4c0 1.1-.9 2-2 2s-2-.9-2-2h-7v4c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V4z"
                  />
                  <path
                    d="M24 0c-2.2 0-4 1.8-4 4s1.8 4 4 4s4-1.8 4-4s-1.8-4-4-4m0 6c-1.1 0-2-.9-2-2s.9-2 2-2s2 .9 2 2s-.9 2-2 2"
                  />
                </g>
                <path fill="#cfd8dc" d="M21 20h12v2H21zm-6-1h4v4h-4z" />
                <path fill="#03a9f4" d="M21 29h12v2H21zm-6-1h4v4h-4z" />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-xl-3">
    <div class="card card-h-100 shadow">
      <div class="card-body">
        <div class="d-flex flex-wrap align-items-center mb-4">
          <h5 class="card-title me-2">Farmers Gender Comparison</h5>
        </div>
        <div class="row align-items-center">
          <div class="col-xl-12">
            <div>
              <div
                style="max-height: 580px"
                echarts
                [options]="genderData"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end card -->
  </div>
  <!-- end col -->
  <div class="col-xl-6">
    <div class="card card-h-100 shadow">
      <div class="card-body">
        <div class="d-flex flex-wrap align-items-center mb-4">
          <h5 class="card-title me-2">Value Chain Income Graph</h5>
        </div>
      </div>
      <div id="line-area" class="lineArea">
        <apx-chart
          [series]="chartOptions?.series"
          [chart]="chartOptions?.chart"
          [xaxis]="chartOptions?.xaxis"
          [legend]="chartOptions.legend"
        ></apx-chart>
      </div>
    </div>
    <!-- end row -->
  </div>
  <div class="col-xl-3">
    <div
      class="card text-white shadow card-h-100 d-flex flex-column align-items-center justify-content-between"
      style="padding: 10px"
    >
      <p class="text-dark card-title me-2" style="font-weight: 700">
        Income distribution by gender
      </p>
      <div
        style="
          width: 95%;
          height: 28%;
          border: 1px solid #e6e6ec;
          border-radius: 5px;
          box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding-top: 20px;
          padding-bottom: 20px;
        "
      >
        <p
          style="
            color: #342e49;
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 0px;
          "
        >
          Total Income
        </p>
        <p
          style="
            color: #3fc057;
            font-size: 36px;
            font-weight: bold;
            margin-bottom: 0px;
          "
        >
          0.00
        </p>
      </div>
      <div
        style="
          width: 95%;
          height: 28%;
          border: 1px solid #e6e6ec;
          border-radius: 5px;
          box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        "
      >
        <p
          style="
            color: #342e49;
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 0px;
          "
        >
          Male Income
        </p>
        <p
          style="
            color: #f77e18;
            font-size: 36px;
            font-weight: bold;
            margin-bottom: 0px;
          "
        >
          0.00
        </p>
      </div>
      <div
        style="
          width: 95%;
          height: 28%;
          border: 1px solid #e6e6ec;
          border-radius: 5px;
          box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        "
      >
        <p
          style="
            color: #342e49;
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 0px;
          "
        >
          Female Income
        </p>
        <p
          style="
            color: #f65252;
            font-size: 36px;
            font-weight: bold;
            margin-bottom: 0px;
          "
        >
          0.00
        </p>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-xl-3 mb-3">
    <div class="card h-100 shadow">
      <div class="card-body">
        <div class="d-flex flex-wrap align-items-center mb-4">
          <h5 class="card-title me-2">Farms VCL Involvement</h5>
        </div>

        <div class="row align-items-center">
          <div class="col-xl-12">
            <div>
              <apx-chart
                [series]="monthlyChart.series"
                [chart]="monthlyChart.chart"
                [legend]="monthlyChart.legend"
                [xaxis]="monthlyChart.xaxis"
                [yaxis]="monthlyChart.yaxis"
                [grid]="monthlyChart.grid"
                [stroke]="monthlyChart.stroke"
                [tooltip]="monthlyChart.tooltip"
                [plotOptions]="monthlyChart.plotOptions"
                [colors]="monthlyChart.colors"
              ></apx-chart>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-xl-9 mb-3">
    <div class="card shadow h-100">
      <div class="card-body">
        <div class="d-flex flex-wrap align-items-center mb-4">
          <h5 class="card-title me-2">Counties Income & Farmers Summary</h5>
        </div>
        <apx-chart
          [series]="countyReport.series"
          [chart]="countyReport.chart"
          [yaxis]="countyReport.yaxis"
          [xaxis]="countyReport.xaxis"
          [labels]="countyReport.labels"
          [stroke]="countyReport.stroke"
          [title]="countyReport.title"
          [dataLabels]="countyReport.dataLabels"
          [colors]="countyReport.colors"
          [tooltip]="countyReport.tooltip"
        ></apx-chart>
      </div>
    </div>
  </div>
</div>

<div>
  <div class="card shadow">
    <div class="card-body">
      <h5 class="card-title me-2">Farmer Trainings</h5>
    </div>

    <div class="row align-items-center">
      <apx-chart
        [series]="trainingChart.series"
        [chart]="trainingChart.chart"
        [legend]="trainingChart.legend"
        [xaxis]="trainingChart.xaxis"
        [yaxis]="trainingChart.yaxis"
        [grid]="trainingChart.grid"
        [stroke]="trainingChart.stroke"
        [tooltip]="trainingChart.tooltip"
        [plotOptions]="trainingChart.plotOptions"
        [colors]="trainingChart.fill.colors"
      ></apx-chart>
    </div>
  </div>
</div>

<!-- pagination.component.html -->
<nav aria-label="Page navigation">
  <ul class="pagination">
    <li class="page-item" [class.disabled]="currentPage === 1">
      <a class="page-link" style="cursor: pointer" (click)="prevPage()"
        >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
        >
          <path
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
            d="M8.065 12.626c.254 1.211 1.608 2.082 4.315 3.822c2.945 1.893 4.417 2.84 5.61 2.475c.403-.124.775-.34 1.088-.635C20 17.418 20 15.612 20 12s0-5.418-.922-6.288a2.8 2.8 0 0 0-1.088-.635c-1.193-.365-2.665.582-5.61 2.475c-2.707 1.74-4.06 2.61-4.315 3.822c-.087.412-.087.84 0 1.252M4 4v16"
            color="currentColor"
          /></svg
      >
    </a>
    </li>
    <li
      class="page-item"
      style="cursor: pointer"
      *ngFor="let page of pages"
      [class.active]="currentPage === page"
    >
      <a class="page-link" (click)="changePage(page)">{{ page }}</a>
    </li>
    <li
      class="page-item"
      style="cursor: pointer"
      [class.disabled]="currentPage === totalPages"
    >
      <a class="page-link" style="cursor: pointer" (click)="nextPage()">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
        >
          <path
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
            d="M15.935 12.626c-.254 1.211-1.608 2.082-4.315 3.822c-2.945 1.893-4.417 2.84-5.61 2.475a2.8 2.8 0 0 1-1.088-.635C4 17.418 4 15.612 4 12s0-5.418.922-6.288a2.8 2.8 0 0 1 1.089-.635c1.192-.365 2.664.582 5.609 2.475c2.707 1.74 4.06 2.61 4.315 3.822c.087.412.087.84 0 1.252M20 5v14"
            color="currentColor"
          />
        </svg>
      </a>
    </li>
  </ul>
</nav>

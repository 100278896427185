<!-- ========== Left Sidebar Start ========== -->
<div class="vertical-menu">
  <ngx-simplebar class="h-100" #sideMenu>
    <!--- Sidemenu -->
    <div id="sidebar-menu">
      <!-- Left Menu Start -->
      <ul class="metismenu list-unstyled" id="side-menu">
        @for (item of menuItems; track $index) {
        <ng-container>
          @if(item.isTitle){
          <li class="menu-title">
            {{ item.label | translate }}
          </li>
          }@else if (!item.isTitle && !item.isLayout) {
          <li>
            @if(hasItems(item)){
            <a
              href="javascript:void(0);"
              class="is-parent"
              [ngClass]="{
                'has-arrow': !item.badge,
                'has-dropdown': item.badge
              }"
            >
              <i-feather name="{{ item.icon }}"></i-feather>
              <span> {{ item.label | translate }}</span>
              @if(item.badge){
              <span
                class="badge rounded-pill float-end badge-soft-{{
                  item.badge.variant
                }} text-danger"
                >{{ item.badge.text | translate }}</span
              >}
            </a>
            }@else{
            <a
              [routerLink]="item.link"
              class="side-nav-link-ref"
              routerLinkActive="active"
            >
              @if(item.icon){
              <i-feather name="{{ item.icon }}"></i-feather>}
              <span> {{ item.label | translate }}</span>
              @if(item.badge){
              <span
                class="badge rounded-pill badge-soft-{{
                  item.badge.variant
                }} float-end"
                >{{ item.badge.text | translate }}</span
              >} </a
            >} @if(hasItems(item)){
            <ul class="sub-menu" aria-expanded="false">
              @for (subitem of item.subItems; track $index) {
              <li>
                @if(hasItems(subitem)){
                <a
                  class="side-nav-link-a-ref has-arrow"
                  href="javascript:void(0);"
                  [attr.data-parent]="subitem.parentId"
                >
                  {{ subitem.label | translate }}
                </a>
                <ul class="sub-menu mm-collapse" aria-expanded="false">
                  @for (subSubitem of subitem.subItems; track $index) {
                  <li>
                    <a
                      [attr.data-parent]="subSubitem.parentId"
                      [routerLink]="subSubitem.link"
                      routerLinkActive="active"
                      class="side-nav-link-ref"
                    >
                      {{ subSubitem.label | translate }}
                    </a>
                  </li>
                  }
                </ul>
                } @else {
                <a
                  [routerLink]="subitem.link"
                  class="side-nav-link-ref"
                  [attr.data-parent]="subitem.parentId"
                  routerLinkActive="active"
                >
                  {{ subitem.label | translate }}
                  @if(subitem.badge){
                  <span
                    class="badge rounded-pill badge-soft-{{
                      subitem.badge.variant
                    }} float-end"
                    >{{ subitem.badge.text | translate }}</span
                  >}
                </a>
                }
              </li>
              }
            </ul>
            }
          </li>
          }
        </ng-container>
        }
      </ul>

      <!-- <div class="card sidebar-alert border-0 text-center mx-4 mb-0 mt-5">
        <div class="card-body">
          <img src="assets/images/giftbox.png" alt="">
          <div class="mt-4">
            <h5 class="alertcard-title font-size-16">Unlimited Access</h5>
            <p class="font-size-13">Upgrade your plan from a Free trial, to select ‘Business Plan’.</p>
            <a href="javascript:void(0);" class="btn btn-primary mt-2">Upgrade Now</a>
          </div>
        </div>
      </div> -->
    </div>
    <!-- Sidebar -->
  </ngx-simplebar>
</div>
<!-- Left Sidebar End -->

<footer class="footer">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6">
               {{year}} © Africa Harvest.
            </div>
            <!-- <div class="col-sm-6">
                <div class="text-sm-end d-none d-sm-block">
                    Designed & Developed by <a href="javascript:void(0);" class="text-decoration-underline">Almatic</a>
                </div>
            </div> -->
        </div>
    </div>
</footer>
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { VlcRoutingModule } from './vlc-routing.module';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    VlcRoutingModule
  ]
})
export class VlcModule { }

<header id="page-topbar">
  <div class="navbar-header">
    <div class="d-flex">
      <!-- LOGO -->
      <div class="navbar-brand-box">
        <a routerLink="/" class="logo logo-dark">
          <span class="logo-sm">
            <img src="assets/images/logo-sm.svg" alt="" height="24" />
          </span>
          <span class="logo-lg">
            <img src="assets/images/logo-sm.svg" alt="" height="24" />
            <span class="logo-txt">AH</span>
          </span>
        </a>

        <a routerLink="/" class="logo logo-light">
          <span class="logo-sm">
            <img src="assets/images/logo-sm.svg" alt="" height="24" />
          </span>
          <span class="logo-lg">
            <img src="assets/images/logo-sm.svg" alt="" height="24" />
            <span class="logo-txt">Minia</span>
          </span>
        </a>
      </div>

      <button
        type="button"
        class="btn btn-sm px-3 font-size-16 header-item d-lg-none"
        id="vertical-menu-btn"
        (click)="toggleMobileMenu($event)"
      >
        <i class="fa fa-fw fa-bars"></i>
      </button>

      <!-- App Search-->
      <form class="app-search d-none d-lg-block">
        <div class="position-relative">
          <input type="text" class="form-control" placeholder="Search..." />
          <button class="btn btn-primary" type="button">
            <i class="bx bx-search-alt align-middle"></i>
          </button>
        </div>
      </form>
    </div>

    <div class="d-flex">
      <div class="dropdown d-inline-block d-lg-none ms-2" ngbDropdown>
        <button
          type="button"
          class="btn header-item"
          id="page-header-search-dropdown"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          ngbDropdownToggle
        >
          <i-feather name="search" class="icon-lg"></i-feather>
        </button>
        <div
          class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
          aria-labelledby="page-header-search-dropdown"
          ngbDropdownMenu
        >
          <form class="p-3">
            <div class="form-group m-0">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Search ..."
                  aria-label="Search Result"
                />

                <button class="btn btn-primary" type="submit">
                  <i class="mdi mdi-magnify"></i>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="dropdown d-none d-sm-inline-block" ngbDropdown>
        <button
          type="button"
          class="btn header-item"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          ngbDropdownToggle
        >
          @if(flagvalue === undefined){
          <img src="{{ valueset }}" alt="Header Language" height="16" />} @else{
          <img src="{{ flagvalue }}" alt="Header Language" height="16" />}
        </button>
        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
          @for (item of listLang; track $index) {
          <a
            href="javascript:void(0);"
            class="dropdown-item notify-item"
            (click)="setLanguage(item.text, item.lang, item.flag)"
            [ngClass]="{ active: cookieValue === item.lang }"
          >
            <img
              src="{{ item.flag }}"
              alt="user-image"
              class="me-1"
              height="12"
            />
            <span class="align-middle">{{ item.text }}</span>
          </a>
          }
        </div>
      </div>

      <div class="dropdown d-none d-sm-inline-block">
        <button type="button" class="btn header-item" id="mode-setting-btn">
          @if(layoutMode == 'light'){
          <ng-container>
            <i-feather
              name="moon"
              class="icon-lg"
              (click)="changeMode('dark')"
            ></i-feather> </ng-container
          >} @if(layoutMode == 'dark'){
          <ng-container>
            <i-feather
              name="sun"
              class="icon-lg"
              (click)="changeMode('light')"
            ></i-feather> </ng-container
          >}
        </button>
      </div>

      <div class="dropdown d-none d-lg-inline-block ms-1" ngbDropdown>
        <button
          type="button"
          class="btn header-item"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          ngbDropdownToggle
        >
          <i-feather name="grid" class="icon-lg"></i-feather>
        </button>
        <div
          class="dropdown-menu dropdown-menu-lg dropdown-menu-end"
          ngbDropdownMenu
        >
          <div class="p-2">
            <div class="row g-0">
              <div class="col">
                <a class="dropdown-icon-item" href="javascript:void(0)">
                  <img src="assets/images/brands/github.png" alt="Github" />
                  <span>GitHub</span>
                </a>
              </div>
              <div class="col">
                <a class="dropdown-icon-item" href="javascript:void(0)">
                  <img
                    src="assets/images/brands/bitbucket.png"
                    alt="bitbucket"
                  />
                  <span>Bitbucket</span>
                </a>
              </div>
              <div class="col">
                <a class="dropdown-icon-item" href="javascript:void(0)">
                  <img src="assets/images/brands/dribbble.png" alt="dribbble" />
                  <span>Dribbble</span>
                </a>
              </div>
            </div>

            <div class="row g-0">
              <div class="col">
                <a class="dropdown-icon-item" href="javascript:void(0)">
                  <img src="assets/images/brands/dropbox.png" alt="dropbox" />
                  <span>Dropbox</span>
                </a>
              </div>
              <div class="col">
                <a class="dropdown-icon-item" href="javascript:void(0)">
                  <img
                    src="assets/images/brands/mail_chimp.png"
                    alt="mail_chimp"
                  />
                  <span>Mail Chimp</span>
                </a>
              </div>
              <div class="col">
                <a class="dropdown-icon-item" href="javascript:void(0)">
                  <img src="assets/images/brands/slack.png" alt="slack" />
                  <span>Slack</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="dropdown d-inline-block" ngbDropdown>
        <button
          type="button"
          class="btn header-item noti-icon position-relative"
          id="page-header-notifications-dropdown"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          ngbDropdownToggle
        >
          <i-feather name="bell" class="icon-lg"></i-feather>
          <span class="badge bg-danger rounded-pill">5</span>
        </button>
        <div
          class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
          aria-labelledby="page-header-notifications-dropdown"
          ngbDropdownMenu
        >
          <div class="p-3">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="m-0">
                  {{ "HEADER.NOTIFICATIONS.TITLE" | translate }}
                </h6>
              </div>
              <div class="col-auto">
                <a
                  href="javascript:void(0);"
                  class="small text-reset text-decoration-underline"
                >
                  {{ "HEADER.NOTIFICATIONS.VIEW_ALL" | translate }}</a
                >
              </div>
            </div>
          </div>
          <ngx-simplebar style="max-height: 230px">
            <a href="javascript:void(0);" class="text-reset notification-item">
              <div class="d-flex">
                <div class="flex-shrink-0 me-3">
                  <img
                    src="assets/images/users/avatar-3.jpg"
                    class="rounded-circle avatar-sm"
                    alt="user-pic"
                  />
                </div>
                <div class="flex-grow-1">
                  <h6 class="mb-1">
                    {{ "HEADER.NOTIFICATIONS.FIRST.TITLE" | translate }}
                  </h6>
                  <div class="font-size-13 text-muted">
                    <p class="mb-1">
                      {{ "HEADER.NOTIFICATIONS.FIRST.TEXT" | translate }}
                    </p>
                    <p class="mb-0">
                      <i class="mdi mdi-clock-outline"></i>
                      <span>{{
                        "HEADER.NOTIFICATIONS.FIRST.TIME" | translate
                      }}</span>
                    </p>
                  </div>
                </div>
              </div>
            </a>
            <a href="javascript:void(0);" class="text-reset notification-item">
              <div class="d-flex">
                <div class="flex-shrink-0 avatar-sm me-3">
                  <span
                    class="avatar-title bg-primary rounded-circle font-size-16"
                  >
                    <i class="bx bx-cart"></i>
                  </span>
                </div>
                <div class="flex-grow-1">
                  <h6 class="mb-1">
                    {{ "HEADER.NOTIFICATIONS.SECOND.TITLE" | translate }}
                  </h6>
                  <div class="font-size-13 text-muted">
                    <p class="mb-1">
                      {{ "HEADER.NOTIFICATIONS.SECOND.TEXT" | translate }}
                    </p>
                    <p class="mb-0">
                      <i class="mdi mdi-clock-outline"></i>
                      <span>{{
                        "HEADER.NOTIFICATIONS.SECOND.TIME" | translate
                      }}</span>
                    </p>
                  </div>
                </div>
              </div>
            </a>
            <a href="javascript:void(0);" class="text-reset notification-item">
              <div class="d-flex">
                <div class="flex-shrink-0 avatar-sm me-3">
                  <span
                    class="avatar-title bg-success rounded-circle font-size-16"
                  >
                    <i class="bx bx-badge-check"></i>
                  </span>
                </div>
                <div class="flex-grow-1">
                  <h6 class="mb-1">
                    {{ "HEADER.NOTIFICATIONS.THIRD.TITLE" | translate }}
                  </h6>
                  <div class="font-size-13 text-muted">
                    <p class="mb-1">
                      {{ "HEADER.NOTIFICATIONS.THIRD.TEXT" | translate }}
                    </p>
                    <p class="mb-0">
                      <i class="mdi mdi-clock-outline"></i>
                      <span>{{
                        "HEADER.NOTIFICATIONS.THIRD.TIME" | translate
                      }}</span>
                    </p>
                  </div>
                </div>
              </div>
            </a>

            <a href="javascript:void(0);" class="text-reset notification-item">
              <div class="d-flex">
                <div class="flex-shrink-0 me-3">
                  <img
                    src="assets/images/users/avatar-6.jpg"
                    class="rounded-circle avatar-sm"
                    alt="user-pic"
                  />
                </div>
                <div class="flex-grow-1">
                  <h6 class="mb-1">
                    {{ "HEADER.NOTIFICATIONS.FOUR.TITLE" | translate }}
                  </h6>
                  <div class="font-size-13 text-muted">
                    <p class="mb-1">
                      {{ "HEADER.NOTIFICATIONS.FOUR.TEXT" | translate }}
                    </p>
                    <p class="mb-0">
                      <i class="mdi mdi-clock-outline"></i>
                      <span>{{
                        "HEADER.NOTIFICATIONS.FOUR.TIME" | translate
                      }}</span>
                    </p>
                  </div>
                </div>
              </div>
            </a>
          </ngx-simplebar>
          <div class="p-2 border-top d-grid">
            <a
              class="btn btn-sm btn-link font-size-14 text-center"
              href="javascript:void(0)"
            >
              <i class="mdi mdi-arrow-right-circle me-1"></i>
              <span>{{ "HEADER.NOTIFICATIONS.LOAD_MORE" | translate }}</span>
            </a>
          </div>
        </div>
      </div>

      <div class="dropdown d-inline-block">
        <button
          type="button"
          class="btn header-item right-bar-toggle me-2"
          (click)="toggleRightSidebar()"
        >
          <i-feather name="settings" class="icon-lg"></i-feather>
        </button>
      </div>

      <div class="dropdown d-inline-block" ngbDropdown>
        <button
          type="button"
          class="btn header-item bg-light-subtle border-start border-end"
          id="page-header-user-dropdown"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          ngbDropdownToggle
        >
          <img
            class="rounded-circle header-profile-user"
            src="assets/images/users/avatar-1.jpg"
            alt="Header Avatar"
          />
          <span class="d-none d-xl-inline-block ms-1 fw-medium">Shawn L.</span>
          <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
          <!-- item-->
          <a class="dropdown-item" routerLink="/apps/profile"
            ><i class="mdi mdi-face-profile font-size-16 align-middle me-1"></i>
            Profile</a
          >
          <a class="dropdown-item" routerLink="/account/lock-screen"
            ><i class="mdi mdi-lock font-size-16 align-middle me-1"></i> Lock
            screen</a
          >
          <div class="dropdown-divider"></div>
          <a
            class="dropdown-item"
            href="javascript: void(0);"
            (click)="logout()"
            ><i
              class="mdi mdi-logout text-muted font-size-16 align-middle me-1"
            ></i>
            <span class="align-middle" key="t-logout">{{
              "MENUITEMS.AUTHENTICATION.LIST.LOGOUT" | translate
            }}</span></a
          >
        </div>
      </div>
    </div>
  </div>
</header>
<div class="topnav">
  <div class="container-fluid">
    <nav class="navbar navbar-light navbar-expand-lg topnav-menu active">
      <div class="collapse navbar-collapse active" id="topnav-menu-content">
        <ul class="navbar-nav">
          @for (item of menuItems; track $index) {
          <ng-container>
            <li class="nav-item dropdown">
              @if(item.subItems){
              <a
                class="nav-link dropdown-toggle arrow-none"
                (click)="onMenuClick($event)"
                href="javascript: void(0);"
                id="topnav-components"
                role="button"
              >
                <i-feather name="{{ item.icon }}"></i-feather>
                {{ item.label | translate }}
                <div class="arrow-down"></div>
              </a>
              }@else {
              <a
                class="nav-link dropdown-toggle arrow-none"
                (click)="onMenuClick($event)"
                href="javascript: void(0);"
                id="topnav-components"
                [routerLink]="item.link"
                role="button"
              >
                <i-feather name="{{ item.icon }}"></i-feather
                >{{ item.label | translate }} @if(hasItems(item)){
                <div class="arrow-down"></div>
                }
              </a>
              } @if(hasItems(item)){
              <div class="dropdown-menu" aria-labelledby="topnav-dashboard">
                <ng-template
                  ngFor
                  let-i="index"
                  let-subitem
                  [ngForOf]="item.subItems"
                >
                  @if(hasItems(subitem)){
                  <div class="dropdown">
                    <a
                      class="dropdown-item dropdown-toggle"
                      href="javascript: void(0);"
                      (click)="onMenuClick($event)"
                      >{{ subitem.label | translate }}
                      <div class="arrow-down"></div>
                    </a>
                    <div class="dropdown-menu">
                      <ng-template
                        ngFor
                        let-subSubitem
                        [ngForOf]="subitem.subItems"
                      >
                        @if(hasItems(subSubitem)){
                        <div class="dropdown">
                          <a
                            class="dropdown-item dropdown-toggle"
                            href="javascript: void(0);"
                            (click)="onMenuClick($event)"
                            >{{ subSubitem.label | translate }}
                            <div class="arrow-down"></div>
                          </a>
                          <div class="dropdown-menu">
                            <ng-template
                              ngFor
                              let-subSubSubitem
                              [ngForOf]="subSubitem.subItems"
                            >
                              <a
                                class="dropdown-item side-nav-link-ref"
                                href="javascript: void(0);"
                                [routerLink]="subSubSubitem.link"
                                routerLinkActive="active"
                                >{{ subSubSubitem.label | translate }}
                              </a>
                            </ng-template>
                          </div>
                        </div>
                        } @else {
                        <a
                          class="dropdown-item side-nav-link-ref"
                          href="javascript: void(0);"
                          [routerLink]="subSubitem.link"
                          routerLinkActive="active"
                          >{{ subSubitem.label | translate }}
                          @if(subSubitem.badge){
                          <span
                            class="badge rounded-pill badge-soft-{{
                              subSubitem.badge.variant
                            }} float-end"
                            >{{ subSubitem.badge.text | translate }}</span
                          >}
                        </a>
                        }
                      </ng-template>
                    </div>
                  </div>
                  }@else {
                  <a
                    class="col dropdown-item side-nav-link-ref"
                    href="javascript: void(0);"
                    [routerLink]="subitem.link"
                    routerLinkActive="active"
                    >{{ subitem.label | translate }}</a
                  >
                  }
                </ng-template>
              </div>
              }
            </li>
          </ng-container>
          }
        </ul>
      </div>
    </nav>
  </div>
</div>
